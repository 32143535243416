import styled from '@emotion/styled'
import React, { memo } from 'react'
import JuicerFeed from 'react-juicer-feed'

export interface Props {
  title?: string
}

export const SocialWall = memo(function SocialWall({ title }: Props) {
  return (
    <Container>
      {title ? <Title>{title}</Title> : null}
      <JuicerFeed feedId="blusuitehotelrimini" />
    </Container>
  )
})

const Container = styled.section`
  background: ${({ theme }) => theme.colors.variants.neutralLight3};
  padding: 9rem 10vw 7.5rem;

  .juicer-feed {
    .referral {
      display: none;
    }
    .j-display-filters {
      margin: 0 0 3.75rem;
      font-size: 0;
      & * {
        font-size: 0.875rem;
      }
      li {
        background: transparent;
        color: ${({ theme }) => theme.colors.variants.neutralDark1};
        font-family: ${({ theme }) => theme.fontFamily.heading};
        font-weight: 600;
        letter-spacing: 0.0437rem;
        line-height: 0.9375rem;
        margin: 0;
        padding: 0.9375rem 1.3125rem;
        &:before {
          display: none;
        }
        &:first-of-type,
        &:last-of-type {
          margin: 0;
        }
        &.all {
          color: ${({ theme }) => theme.colors.variants.neutralDark1};
          &:hover {
            background: transparent !important;
            border: 0 !important;
            color: ${({ theme }) => theme.colors.variants.primaryDark1};
          }
          &.highlight {
            background: ${({ theme }) =>
              theme.colors.variants.neutralDark1} !important;
            border: 0 !important;
            color: ${({ theme }) =>
              theme.colors.variants.neutralLight4} !important;
          }
        }
        &:hover {
          background: transparent !important;
          border: 0 !important;
          color: ${({ theme }) => theme.colors.variants.primaryDark1};
        }
        &.highlight {
          background: ${({ theme }) =>
            theme.colors.variants.neutralDark1} !important;
          border: 0 !important;
          color: ${({ theme }) =>
            theme.colors.variants.neutralLight4} !important;
        }
      }
    }
    .feed-item {
      &.juicer {
        display: none !important;
      }
    }
  }
`

const Title = styled.h1`
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-size: 4.375rem;
  line-height: 5.25rem;
  margin-bottom: 2.25rem;
  text-align: center;
`
